import { useEffect, useState, useMemo } from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';

import Librairie from './Librairie';
import Lecteur from './Lecteur';
import { deepCopy } from "../shared/Objets";

function App() 
{
  // memos
  const STATUT_MORCEAU = useMemo(() => {
    return {POUBELLE: 0, LECTEUR: 1, FILE_ATTENTE: 2, LIBRAIRIE: 3};
  }, []);

  // states
  const [ charge, setCharge ] = useState(false);        // détrompeur qui évite de charger deux fois la liste des morceaux au chargement
  const [ listeMorceaux, setListeMorceaux ] = useState([]);  // liste des morceaux

  const [ hauteurTitre, setHauteurTitre ] = useState(80);  // liste des morceaux
  const [ hauteurLibrairie, setHauteurLibrairie ] = useState(window.innerHeight - hauteurTitre);  // liste des morceaux
  const [ hauteurFileDAttente, setHauteurFileDAttente ] = useState(0.86 * window.innerHeight - hauteurTitre);  // liste des morceaux

  // fonction d'ajout d'un titre à la liste d'attente
  function ajouterALaListeDeLecture(morceauId)
  {
    // on fait une copie de la liste des morceaux qui est dans le state
    // sinon on ne pourra pas déclencher de modification du state à la fin de cette méthode
    const copieMorceaux = deepCopy(listeMorceaux);

    // on cherche le morceau à partir de son identifiant
    const morceauALire = copieMorceaux.find(m => m.id === morceauId);
    if (morceauALire === undefined) return;

    // on passe le morceau dans la liste d'attente, en horodatant cet ajout (pour conserver l'ordre d'ajout dans la file d'attente)
    // sauf si il n'y a pas d'autre morceau en lecture, auquel cas on le passe tout de suite dans le lecteur
    const moreceauEnLecture = copieMorceaux.find(m => m.statut === STATUT_MORCEAU.LECTEUR) !== undefined;

    morceauALire.statut = moreceauEnLecture ? STATUT_MORCEAU.FILE_ATTENTE : STATUT_MORCEAU.LECTEUR;
    morceauALire.dateAjout = new Date();

    // et on déclenche un changement de l'état
    setListeMorceaux(copieMorceaux);
  }
  
  // fonction d'ajout d'un titre à la lecture
  function ajouterAuLecteur(morceauId)
  {
    // on fait une copie de la liste des morceaux qui est dans le state
    // sinon on ne pourra pas déclencher de modification du state à la fin de cette méthode
    const copieMorceaux = deepCopy(listeMorceaux);

    // on cherche le morceau à partir de son identifiant parmi les fichiers en liste d'attente ou en librairie
    // les morceaux déjà lus ou celui qui est en cours de lecture ne peuvent pas être remis en lecture
    const morceauALire = copieMorceaux.find(m => m.id === morceauId);
    if ((morceauALire === undefined) || (morceauALire.statut === STATUT_MORCEAU.POUBELLE) || (morceauALire.statut === STATUT_MORCEAU.LECTEUR)) return;

    // on passe le morceau dans le lecteur, en horodatant cet ajout (pour conserver l'ordre d'ajout dans la file d'attente)
    morceauALire.statut = STATUT_MORCEAU.LECTEUR;
    morceauALire.dateAjout = new Date();

    // et on déclenche un changement de l'état
    setListeMorceaux(copieMorceaux);
  }

  // fonction qui marque un morceau comme ayant été joué et ne pouvant plus l'être
  function terminerMorceau(morceauId)
  {
    // on fait une copie de la liste des morceaux qui est dans le state
    // sinon on ne pourra pas déclencher de modification du state à la fin de cette méthode
    const copieMorceaux = deepCopy(listeMorceaux);

    // on cherche le morceau à partir de son identifiant parmi tous les fichiers qui ne sont pas déjà lus
    const morceauALire = copieMorceaux.find(m => m.id === morceauId);
    if ((morceauALire === undefined) || (morceauALire.statut === STATUT_MORCEAU.POUBELLE)) return;

    // on passe le morceau dans la poubelle, en horodatant cet ajout
    morceauALire.statut = STATUT_MORCEAU.POUBELLE;
    morceauALire.dateAjout = new Date();

    // et on déclenche un changement de l'état
    setListeMorceaux(copieMorceaux);
  }

  // génération de symboles pour encadrer le nom de l'auteur
  function genererSymboles()
  {
      // les symboles sont créés par une série de 3 lettres qui seront affichées dans une police de symboles
      // la série des 3 est présente dans un sens à gauche puis dans le sens inverse à droite
      // certians caractères sont symétriques, donc ils sont identique sà gauche et à droite
      // d'autres ont un sens d'affichage, ils sont donc représentés par une paire de lettres, pour le splacer à gauche puis à droite
      const symetriques = "ABCDEJLMNOPQVWXYbegmnopqtuvwxy013";        // ces symboles peuvent être ajoutés à gauche et à droite
      const gauche = "28h6I";     // ceux-là seulement à gauche
      const doite = "k9s7T";      // avec leur pendant à droite
      let symbolesAvant = "", symbolesApres = "";
      
      // on tire trois symboles au hasard parmi tous ceux qui sont possibles
      const nombreSymbolesSymetriques = symetriques.length;
      const nombreSymboles = nombreSymbolesSymetriques + gauche.length;
      for (let numeroSymbole = 0; numeroSymbole < 3; numeroSymbole++)
      {
          // on tire un numéro de symbole au hasard
          let indexSymbole = Math.floor(Math.random() * nombreSymboles);

          // si le symbole est symétrique, on j'ajoute à gauche d'un côté et à droite
          if (indexSymbole < nombreSymbolesSymetriques)
          {
              const caractereSymbole = symetriques.substring(indexSymbole, indexSymbole + 1);
              // en faisant attention à écrire la série de symbole dans deux sens différents (eg. ABC à gauche, CBA à droite)
              symbolesAvant = symbolesAvant + caractereSymbole;
              symbolesApres = caractereSymbole + symbolesApres;
          }
          else 
          {
              // pour les symlboles orientés, on met le symbole de gauche à gauche et le symbole de droite sur le bouton rouge
              indexSymbole -= nombreSymbolesSymetriques;
              symbolesAvant = symbolesAvant + gauche.substring(indexSymbole, indexSymbole + 1);
              symbolesApres = doite.substring(indexSymbole, indexSymbole + 1) + symbolesApres;
          }
      }
      
      // on retourne dans un tableau les deux séries de symboles de gauche et de droite
      return [
          <Typography component="span" sx={{ fontFamily: "PoliceSymboles", fontSize: "1.5em", paddingRight: "2em", color:"#E88284" }} align="right" >{symbolesAvant}</Typography>,
          <Typography component="span"  sx={{ fontFamily: "PoliceSymboles", fontSize: "1.5em", paddingLeft: "2em", color:"#E88284" }} align="left" >{symbolesApres}</Typography>
      ];
  }

  // au chargement du composant on récupère la liste des pistes disponibles que l'on plac ene librairie avec des symboles gauche / droite pour l'auteur
  useEffect(() => 
  {
      // au 1er rendu on ne déclenche pas le chargement (à cause du double rendu React au chargement du composant)
      if (!charge)
      {
          setCharge(true);
          return;
      }

      // on charge la liste des morceaux
      fetch(
          "https://poc-pwa.kaliva.fr/getLibrairie.php",
          {
              mode: "cors",
              method: "GET",
          }
      )
      .then((response) => 
      {
        if (!response.ok) 
          {
          throw new Error(`Erreur HTTP : ${response.status}`);
        }
        return response.json();
      })
      .then((data) => 
      { 
          const donneesMorceaux = Array.isArray(data) ? data : [];

          // on ajoute aux données les symboles qui encadreront les noms à gauche et à droite
          for (let i = 0; i < donneesMorceaux.length; i++)
          {
              const [symbolesGauche, syumbolesDroite] = genererSymboles();
              donneesMorceaux[i].symbolesGauche = symbolesGauche;
              donneesMorceaux[i].symbolesDroite = syumbolesDroite;

              // au 1er chargement tous les morceaux sont dans la librairie
              donneesMorceaux[i].statut = STATUT_MORCEAU.LIBRAIRIE;
              donneesMorceaux[i].dateAjout = new Date();
          }

          setListeMorceaux(donneesMorceaux);
      })
      .catch((err) => console.error(`Problème avec Fetch : ${err.message}`));

  }, [charge, setCharge, STATUT_MORCEAU ]);
  
  // effet utilisé pour rendre toute la page non sélectionnable à la souris
  useEffect(() => 
  {
    const noSelectElements = document.querySelectorAll(".no-select");
    noSelectElements.forEach((element) => 
    {
        element.style.webkitUserSelect = "none";
        element.style.mozUserSelect = "none";
        element.style.msUserSelect = "none";
        element.style.userSelect = "none";
    });

  }, []);

  // on réagit aux changement de taille de la fenêtre pour correctement redimensionner les composants
  window.addEventListener("resize", calculerHauteurs);

  // méthode qui calcule les hauteurs des composants selon la tailel de la fenêtre; appelée quand la fenêtre change de taille
  function calculerHauteurs()
  {
    setHauteurTitre(80);
    setHauteurLibrairie(window.innerHeight - hauteurTitre);
    setHauteurFileDAttente(0.86 * window.innerHeight - hauteurTitre);
  }

  // rendu du composant
  return (
    <Box sx={{ height:"100vh", display:"flex", flexDirection:"column" }} className="no-select">
      <Grid container spacing={0}>
        {/* Titre du jukebox */}
        <Grid size={12} sx={{ height: hauteurTitre, backgroundColor: "brown" }} >
          <Typography align="center" 
            sx={{ 
              fontFamily: "PoliceEnTete", color: "white", marginTop: "3px", fontSize: "2em",
              textShadow: "0px 1px 0px #808d93, 1px 0 0px #cdd2d5, 1px 2px 1px #808d93, 2px 1px 1px #cdd2d5, 2px 3px 2px #808d93, 3px 2px 2px #cdd2d5, " + 
                "3px 4px 2px #808d93, 4px 3px 3px #cdd2d5, 4px 5px 3px #808d93, 5px 4px 2px #cdd2d5, 5px 6px 2px #808d93, 6px 5px 2px #cdd2d5, " +
                "6px 7px 1px #808d93, 7px 6px 1px #cdd2d5, 7px 8px 0px #808d93, 8px 7px 0px #cdd2d5"
            }}
          >
            Silly Juke Box
          </Typography>
          <Typography align="center" sx={{ fontFamily: "PoliceLegende", color: "lightgrey", marginTop: "1px", fontSize: "0.82em", fontWeight: 100, letterSpacing: 1.5 }}>
            Choisissez en cliquant un morceau à droite de l'écran. 
            A gauche c'est le morceau qui se joue, suivi de la file d'attente. 
            Voilà, voilà...
          </Typography>         
        </Grid>
        {/* Colonne de gauche : lecture et suivants */}
        <Grid key="gauche" size={3} sx={{ height: hauteurLibrairie }} >
          <Grid container spacing={0} direction="column">
            {/* Piste en lecture */}
            <Grid key="lecture" size={2} sx={{ height: hauteurLibrairie - hauteurFileDAttente, backgroundColor: "#AAABAC" }}>
              <Lecteur 
                listeMorceaux={listeMorceaux.filter(m => m.statut !== STATUT_MORCEAU.TERMINE)} 
                terminerMorceau={terminerMorceau} ajouterAuLecteur={ajouterAuLecteur}
              />
            </Grid>
            {/* Pistes en attente de lecture */}
            <Grid key="fileattente" size={10} sx={{ height: hauteurFileDAttente, backgroundColor: "#AAABAC", overflowY: "auto", overflowX: "hidden" }}>
              <Librairie 
                listeMorceaux={listeMorceaux.filter(m => m.statut === STATUT_MORCEAU.FILE_ATTENTE)} 
                taille={12}
              />
            </Grid>
          </Grid>
        </Grid>
        {/* Colonne de droite : bibliothèque */}
        <Grid key="droite" size={9} 
          sx={{ 
            height:hauteurLibrairie, backgroundColor: "#AAABAC", overflowY: "auto", overflowX: "hidden",  
          }}
        >
          <Librairie 
            listeMorceaux={listeMorceaux.filter(m => m.statut === STATUT_MORCEAU.LIBRAIRIE)} 
            ajouterALaListeDeLecture={ajouterALaListeDeLecture} 
            taille={4}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default App;
