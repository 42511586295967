import { useRef, useEffect } from 'react';

import { validerFonction } from '../shared/Validations';

function Canevas ({dessiner, hauteur, largeur})
{
    // on créé une référence DOM du composant pour pouvoir ensuite adresser le caneva en Javascript
    const referenceComposant = useRef(null);

    // on va pouvoir utiliser le canevas une fois le composant monté, pas avant
    useEffect(() => 
    {
        // récupération du canevas 2D du composant
        const canevas = referenceComposant.current;
        const contexte = canevas.getContext('2d');

        // Définir la couleur de fond du canevas
        //contexte.fillStyle = 'rgba(0, 0, 0, 0.1)'; // Teinte légèrement le fond
        //contexte.fillRect(0, 0, canevas.width, canevas.height);

        // appel de la procédure qui dessine le composant
        if (validerFonction(dessiner)) 
        {
            dessiner(contexte);
        }

    }, [dessiner, hauteur, largeur]);

    return <canvas ref={referenceComposant} height={hauteur} width={largeur} />;
}

export default Canevas;