import { useEffect, useState, useMemo } from 'react';

import Slider from '@mui/material/Slider';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Cartouche from './Cartouche';
import {nullOuIndefini} from '../shared/Validations';

function Lecteur({ listeMorceaux, terminerMorceau, ajouterAuLecteur })
{
    // memos
    const STATUT_MORCEAU = useMemo(() => {
      return {POUBELLE: 0, LECTEUR: 1, FILE_ATTENTE: 2, LIBRAIRIE: 3};
    }, []);

    const lecteurAudio = useMemo(() => {
        const audio = new Audio();
        audio.preload = "metadata";
        return audio;
      }, []);

    // calcul d'un drapeau qui indique si un morceau est en cours de lecture
    const lectureEnCours = (lecteurAudio.currentTime > 0) && !lecteurAudio.ended && lecteurAudio.readyState > 2;
    
    // on cherche le morceau qui est en cours de lecture (s'il y en a un)
    const morceauDuLecteur = Array.isArray(listeMorceaux) ? listeMorceaux.find(m => m.statut === STATUT_MORCEAU.LECTEUR) : undefined;

    // states
    const [ demarre, setDemarre ] = useState(false);                                        // indicateur de démarrage du lecteur
    const [ morceauEnCoursDeLecture, setMorceauEnCoursDeLecture ] = useState(null);         // morceau en cours de lecture
    const [ morceauPosition, setMorceauPosition ] = useState(null);                         // position de la tête de lecture sur le morceau en cours de lecture

    // gestion du démarrage d'une lecture de morceau
    useEffect(() =>
    {
        // une lecture est elle en cours : si oui on arrête là, pas de démarrage à faire
        if (lectureEnCours)
        {
            return;
        }

        // existe-t-il une piste à mettre en lecture ? Si oui on en lance le chargement et on marque le lecteur comme démarré
        const morceauAJouer = Array.isArray(listeMorceaux) ? listeMorceaux.find(m => m.statut === STATUT_MORCEAU.LECTEUR) : undefined;
        if (morceauAJouer !== undefined)
        {
            // on charge le morceau dans le lecteur audio, on positionne les states du composant en lecture de celui-ci
            lecteurAudio.src = "https://poc-pwa.kaliva.fr/getMorceau.php?id=" + morceauAJouer.id;
            setDemarre(true);
            setMorceauEnCoursDeLecture(morceauAJouer);

            // on exécute aussi un script sur le serveur pour enregistrer la date et l'heure de la lecture du morceau
            fetch(
                "https://poc-pwa.kaliva.fr/setMorceauJoue.php?id=" + morceauAJouer.id,
                {
                    mode: "cors",
                    method: "GET",
                }
            )
            .then((response) => 
            {
              if (!response.ok) 
              {
                throw new Error("setMorceauJoue[" + morceauAJouer.id + "] Erreur HTTP :" + response.status);
              }
              return response.json();
            })
            .then((data) => 
            { 
                console.info("setMorceauJoue[" + morceauAJouer.id + "] OK:", data.toString());
            })
            .catch((err) => console.error("setMorceauJoue[" + morceauAJouer.id + "] Erreur :" + err.message));

            return;
        }

        // si aucun morceau n'est à la lecture, y-a-t-il des morceaux en file d'attente ?
        // si oui, on prend celui qui y a été ajouté lepremier
        const morceauxEnFileDAttente = Array.isArray(listeMorceaux) ? 
            listeMorceaux.filter(m => m.statut === STATUT_MORCEAU.FILE_ATTENTE).sort(triDateAjout) : 
            [];

        if (morceauxEnFileDAttente.length > 0) 
        {
            // on envoi le morceau dans le lecteur, via la modification de son statut dans le composant parent
            ajouterAuLecteur(morceauxEnFileDAttente[0].id);
            return;
        }

        // sinon, si le lecteur est démarré (sinon on risque de lancer le jukebox sans interraction de l'utilisateur ce qui est interdit sur Chrome & FFOX)
        // et si la librairie n'est pas vide on choisi un morceau au hasard et on le met en lecture
        const morceauxEnLibrairie = listeMorceaux.filter(m => m.statut === STATUT_MORCEAU.LIBRAIRIE);
        if (demarre && Array.isArray(morceauxEnLibrairie) && morceauxEnLibrairie.length > 0)
        {
            // on tire au hasard le morceau de la librairie à lancer
            const indiceMorceauAJouer = Math.floor(Math.random() * morceauxEnLibrairie.length);

            // le morceau va se lancer par modification de son statut dans le composant parent
            ajouterAuLecteur(morceauxEnLibrairie[indiceMorceauAJouer].id);
            return;
        }
    }, [ demarre, setDemarre, lecteurAudio, listeMorceaux, STATUT_MORCEAU, ajouterAuLecteur, lectureEnCours ]);

    // fonction de tri de morceaux par dates d'ajouts
    function triDateAjout(a, b)
    {
        return a.dateAjout > b.dateAjout ? 1 : a.dateAjout < b.dateAjout ? -1 : 0;
    }

    // capteur dévénement du lecteur : morceau prêt à être joué, récupération des metadata, porgession de lecture, fin du morceau
    lecteurAudio.addEventListener("canplaythrough", function() 
    {
        lecteurAudio.play();
    });

    // capteur d'événement du lecteur : avancée dans lemorceau, permet de repositionner la barre de lecture
    lecteurAudio.addEventListener("timeupdate", function()
    {
        setMorceauPosition(lecteurAudio.currentTime);
    });

    // capteur d'événement du lecteur : fin du morceau
    lecteurAudio.addEventListener("ended", function() 
    {
        if (morceauDuLecteur !== undefined)
        {
            // on termine le morceau en changeant son statut dans le composant parent
            terminerMorceau(morceauDuLecteur.id);
            // on mémorise localement le fait qu'il n'y a plus de morceau en lecture
            setMorceauEnCoursDeLecture(null);
        }
        setMorceauPosition(null);
    });

    // fonction qui formate un temps en m:ss
    function formatDuration(temps) 
    {
        const minutes = Math.floor(temps / 60);
        const secondes = Math.floor(temps - (minutes * 60));
        const tempsFormate =  `${minutes}:${secondes < 10 ? `0${secondes}` : secondes}`;
        return tempsFormate;
    }

    // calcul des temps à afficher dans la barre de lecture (temps de lecteur déjà joué, temps restant)
    const position = nullOuIndefini(morceauPosition) || isNaN(morceauPosition) ? -1 : morceauPosition;
    const dureeTotale = nullOuIndefini(morceauDuLecteur) || isNaN(morceauDuLecteur.duree) || (morceauDuLecteur.duree <= 0) ? 0 : morceauDuLecteur.duree;
    const afficherProgression = (position > -1) || (dureeTotale > 0);

    const dureeLue = afficherProgression ? formatDuration(morceauPosition) : "";
    const dureeALire = afficherProgression ? "-" + formatDuration(dureeTotale - position) : "";

    // rendu du composant
    return nullOuIndefini(morceauEnCoursDeLecture) || (morceauEnCoursDeLecture.statut === STATUT_MORCEAU.POUBELLE) ?
        <></>
        :
        <>
            {/* Cartouche dessinant le titre et son auteur, comme dans la file d'attente et dans la librairie */}
            <Cartouche 
                morceau={morceauEnCoursDeLecture} 
                sx={{                     
                    zOrder: 100, position: "relative",
                    left: 0, top: 0
                }}
            />
            {/* Position de la tête de lecture */}
            { afficherProgression &&
                <Slider 
                    size="small" value={position} min={0} max={dureeTotale} step={0.01}
                    sx={{
                        zOrder: 200, position: "relative", left: "18%", top: "0px", width: "64%",
                        color: 'rgba(0,0,0,0.87)', height: 4,
                        '& .MuiSlider-thumb': {
                            width: 2, height: 2,
                            transition: '0.1s cubic-bezier(.47,1.64,.41,.8)',
                            '&::before': { boxShadow: '0 2px 12px 0 rgba(0,0,0,0.4)', },
                            '&:hover, &.Mui-focusVisible': { boxShadow: `0px 0px 0px 2px ${'rgb(0 0 0 / 16%)'}`, },
                            '&.Mui-active': { width: 2, height: 2 },
                        },
                        '& .MuiSlider-rail': { opacity: 0.28, },
                    }}
                /> 
            }
            {/* Temps de lecture (ecoulé à gauche et restant à droite) */}
            { afficherProgression &&
                <Box
                    sx={{
                        zOrder: 300, position: "relative",
                        left: "10%", top: "-14px", width: "80%",
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        mt: -2
                    }}
                >
                    <Typography sx={{ fontSize: '0.8rem', opacity: 0.38, fontWeight: 850, letterSpacing: 0.2 }} >{dureeLue}</Typography>
                    <Typography sx={{ fontSize: '0.8rem', opacity: 0.38, fontWeight: 850, letterSpacing: 0.2 }} >{dureeALire}</Typography>
                </Box> 
            }
        </>
    ;
}

export default Lecteur;