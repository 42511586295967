import { nullOuIndefini } from './Validations';

// Tracer une ligne sur un contexte de canevas.
// Paramètres :
// - contexte : le contexte 2D du canevas sur lequel la ligne sera tracée.
// - gauche : coordonnée x du point de départ de la ligne.
// - haut : coordonnée y du point de départ de la ligne.
// - droite : coordonnée x du point d'arrivée de la ligne.
// - bas : coordonnée y du point d'arrivée de la ligne.
// - couleur : couleur de la ligne (par défaut "black").
// - epaisseur : épaisseur de la ligne (par défaut 1).

/**
 * Fonction qui trace une ligne entre deux points dans un canevas
 * @param {any} contexte : contexte du canevas
 * @param {any} gauche : coordonnée X de l'origine, pixels
 * @param {any} haut : coordonnée Y de l'origine, pixels
 * @param {any} droite : coordonnée X de la destination, pixels
 * @param {any} bas : coordonnée Y de la destination, pixels
 * @param {any} couleur : couleur du trait (noir par défaut)
 * @param {any} epaisseur : épaisseur du trait (1 par défaut), pixels
 */
export function tracerLigne (contexte, gauche, haut, droite, bas, couleur = "black", epaisseur = 1)
{
    // on commence par sauver l'état du contexte pour pouvoir le restaurer à la fin pour ne pas le poluer avec les modifications que l'on va lui apporter temporairement
    contexte.save();

    contexte.strokeStyle = couleur;
    contexte.lineWidth = epaisseur;
    contexte.beginPath();
    contexte.moveTo(gauche, haut);
    contexte.lineTo(droite, bas);
    contexte.closePath();
    contexte.stroke();

    // restauration du contexte initial
    contexte.restore();
};

// tracé d'un rectangle avec arrondi des bords et ombrage interne possibles
export function tracerRectangle (contexte, gauche, haut, largeur, hauteur, couleur = "black", arrondi = 0, ombre = 0, couleurOmbre = "#00000000")
{
    // on commence par sauver l'état du contexte pour pouvoir le restaurer à la fin pour ne pas le poluer avec les modifications que l'on va lui apporter temporairement
    contexte.save();

    contexte.beginPath();
    contexte.moveTo(gauche + arrondi, haut);
    contexte.lineTo(gauche + largeur - arrondi, haut);
    contexte.quadraticCurveTo(gauche + largeur, haut, gauche + largeur, haut + arrondi);
    contexte.lineTo(gauche + largeur, haut + hauteur - arrondi);
    contexte.quadraticCurveTo(gauche + largeur, haut + hauteur, gauche + largeur - arrondi, haut + hauteur);
    contexte.lineTo(gauche + arrondi, haut + hauteur);
    contexte.quadraticCurveTo(gauche, haut + hauteur, gauche, haut + hauteur - arrondi);
    contexte.lineTo(gauche, haut + arrondi);
    contexte.quadraticCurveTo(gauche, haut, gauche + arrondi, haut);
    contexte.closePath();

    if (ombre > 0)
    {
        contexte.shadowColor = couleurOmbre;
        contexte.shadowBlur = ombre;
        contexte.shadowOffsetX = contexte.shadowOffsetY = ombre / 2;
    }

    contexte.lineWidth=0;
    contexte.strokeStyle = couleur;
    contexte.stroke();

    contexte.shadowColor='rgba(0,0,0,0)';

    contexte.globalCompositeOperation='destination-in';
    contexte.fill();

    contexte.globalCompositeOperation='destination-over';
    contexte.fillStyle = couleur;
    contexte.fill();

    contexte.globalCompositeOperation='source-over';

    // restauration du contexte initial
    contexte.restore();
}

// tracé d'un cercle, sur la base d'un tracé d'ellipse
export function tracerCercle (contexte, x, y, rayon, epaisseur = 2)
{
    tracerEllipse(contexte, x, y, rayon, rayon, epaisseur = 2);
}

// tracé du contour d'une ellipse ou d'un cercle dans un canevas
export function tracerEllipse (contexte, x, y, rayonX, rayonY, epaisseur = 2)
{
    // on commance par sauver l'état du contexte pour pouvoir le restaurer à la fin pour ne pas le poluer avec les modifications que l'on va lui apporter temporairement
    contexte.save();

    contexte.lineWidth = epaisseur;

    contexte.beginPath();
    contexte.ellipse(x, y, rayonX, rayonY, 0, 0, 2 * Math.PI);
    contexte.closePath();
    contexte.stroke();

    // restauration du contexte initial
    contexte.restore();
};

// Tracé d'un texte centré sur une ligne, à une position donnée de l'écran et une largeur maximale. Le texte est écrit à l'aide d'une police donnée (taille, famille et couleur) sur une couleur de fond facultative
// - contexte: contexte de tracé d'un canevas
// - x : position horizontale du texte dans le canevas
// - y : position verticale du texte dans le canevas
// - largeur : largeur maximum du texte
// - texte : texte à afficher
// - police : style de la police à utiliser (en précisant sa taille et sa famille) On peut aussi préciser ne 1er le style italic, bold, etc. (https://developer.mozilla.org/fr/docs/Web/CSS/font)
// - couleurTexte : couleur d'écriture du texte
// - couleurFond : (facultatif) si une couleur de fond est définie le texte sera inscrit sur un rectangle coloré
// - padding : (facultatif, 0 par défaut) ajoute une marge interne entre la couleur de fond et le texte. Non utilisé s'il n'y a pas de couleur de fond précisée
// - marge : (facultatif, 0 par défaut) largeur d'une gouttière placée autour du texte (pixels)
export function tracerTexteCentre (contexte, x, y, largeur, texte, police, couleurTexte = "black", couleurFond = null, padding = 0, marge = 0)
{
    // on commence par sauver l'état du contexte pour pouvoir le restaurer à la fin pour ne pas le poluer avec les modifications que l'on va lui apporter temporairement
    contexte.save();

    // on change la police du contexte
    contexte.font = police;
    contexte.textBaseline = "top";
    contexte.textAlign = "center";
    
    // si une couleur de fond est spécifiée on commence par tracer un rectangle de fond, en y ajoutant la marge de tous les côtés
    const metriques = contexte.measureText(texte);
    const doublePadding = 2 * padding;
    const avecFond = !nullOuIndefini(couleurFond);

    if (avecFond)
    {
        contexte.fillStyle = couleurFond;
        const hauteurRectangle = (nullOuIndefini(metriques.fontBoundingBoxAscent) | - nullOuIndefini(metriques.fontBoundingBoxDescent)) ?
            metriques.actualBoundingBoxAscent + metriques.actualBoundingBoxDescent + 4:
            metriques.fontBoundingBoxAscent + metriques.fontBoundingBoxDescent;
        
        contexte.fillRect(x + marge, y + marge, largeur - marge, hauteurRectangle + doublePadding - marge);
    }

    // et on fini par écrire le texte, en le déplaçant selon la marge
    contexte.fillStyle = couleurTexte;
    contexte.fillText(texte, x + largeur / 2 + (avecFond ? padding : 0) + marge, y + (avecFond ? padding : 0) + marge + 1, largeur - (avecFond ? padding : 0) - marge);

    // restauration du contexte initial
    contexte.restore();
}