
// méthode complète de copie d'objets par récursivité
export const deepCopy = inObject =>
    {
        let outObject, value, key
    
        if (typeof inObject !== "object" || inObject === null) {
            return inObject // retourne simplement la valeur si la variable n'est pas un objet 
        }
    
        // on crée un tableau ou un objet pour contenir le résultat 
        outObject = Array.isArray(inObject) ? [] : {}
    
        // on boucle sur les items ou propriétés du tableau ou de l'objet
        for (key in inObject) {
            value = inObject[key]
    
            // L'objet Date ne fonctionnera pas par copie récursive (évidemment...), dans ce cas on le traite avec sa propre méthode
            if (value instanceof Date) {
                outObject[key] = new Date(value.getTime());
            }
            else
            {
                // sinon on peut faire la copie récursive de l'item/propriété du tableau/objet          
                outObject[key] = ((typeof value === "object") && (value !== null)) ? deepCopy(value) : value
            }
        }
    
        // quand tout est copié on retourne le résultat
        return outObject
    }
    